import * as React from 'react'
// hooks
import { useAuth0 } from '@auth0/auth0-react';

// ----------------------------------------------------------------------
const SignUp = () => {
    useAuth0().loginWithRedirect({ screen_hint: 'signup' });
    return <></>;//<div>Login...</div>;
}

export default SignUp